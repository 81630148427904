import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import { NotFoundPage } from '../global.css';

const NotFound = () => {
  const [scriptsLoading, setScriptsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
  }, []);

  return scriptsLoading ? (
    ''
  ) : (
    <Layout
      meta={{
        pageTitle: 'Server error',
        pageDescription:
          'Our server encountered an error, please come back later',
      }}
    >
      <NotFoundPage>
        <h1>Server error</h1>
        <p>Our server encountered an error, please come back later.</p>
      </NotFoundPage>
    </Layout>
  );
};

export default NotFound;
